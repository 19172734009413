import { NgModule, APP_INITIALIZER, ComponentFactoryResolver } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { Network } from '@ionic-native/network/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { CookieModule } from 'ngx-cookie';
import { AppLoadService } from './services/app-load/app-load.service';
import { CloseSessionModalComponent } from './components/modals/close-session/close-session-modal.component';
import { PopoverHeaderProfileComponent } from './components/popover-header-profile/popover-header-profile.component';
import { CommonModule } from '@angular/common';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { SendMailComponent } from './components/modals/send-mail/send-mail.component';
import { MailPillComponent } from './components/mail-pill/mail-pill.component';
import { HeaderComponent } from './components/header/header.component';
import 'hammerjs';
import { DeviceService } from './services/device/device.service';

import { PublicClientApplication, InteractionType, ProtocolMode, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { MsalModule, MsalRedirectComponent, MsalGuard } from '@azure/msal-angular';
import { EventService } from './services/events';


// * MSAL
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


export const init_app_language = (appLoadService: AppLoadService): any  => () => appLoadService.initializeAppLanguage();

export const init_app_componentConfig = (appLoadService: AppLoadService): any  => () => appLoadService.initializeAppComponentConfig();

@NgModule({
  declarations: [
    AppComponent,
    CloseSessionModalComponent,
    PopoverHeaderProfileComponent,
    SendMailComponent,
    MailPillComponent
  ],
  entryComponents: [
    PopoverHeaderProfileComponent,
    SendMailComponent,
    MailPillComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CookieModule.forRoot(),
    IonicStorageModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    CommonModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        authority: environment.msal.authority,
        clientId: environment.msal.clientId,
        redirectUri: environment.msal.redirectUri,
        postLogoutRedirectUri: environment.msal.postLogoutRedirectUri,
        protocolMode: ProtocolMode.AAD,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: isIE,
      },
    }), {
      interactionType: InteractionType.Redirect,
      authRequest: {
          scopes: environment.msal.scopes
      },
      loginFailedRoute: environment.msal.redirectUri
    },
    null)
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Network,
    HttpClientModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppLoadService,
    FileOpener,
    File,
    Platform,
    DeviceService,
    { provide: APP_INITIALIZER, useFactory: init_app_componentConfig, deps: [AppLoadService], multi: true },
    { provide: APP_INITIALIZER, useFactory: init_app_language, deps: [AppLoadService], multi: true },
    EventService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
