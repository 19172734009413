import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';

import { UserService } from '../user/user.service';
import { environment } from 'src/environments/environment';
import { AzureAuthService } from '../azure/azureauth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private userService: UserService,
    private navController: NavController,
    private azureAuthService: AzureAuthService
  ) {

  }

  checkAccess(path: string, resolve: Function) {
    if (this.userService.isLoggedIn()) {
      if (path === 'login') {
        resolve(false);
        this.navController.navigateRoot('home');
      } else {
        resolve(true);
      }
    } 
    else {
      if(!environment.basicAuth && path === 'sso'){
        resolve(true);
      }
      else if(!environment.basicAuth && path !== 'sso'){
        this.azureAuthService.enableSso = true;
        this.navController.navigateRoot('sso');
        resolve(false);
      }
      if ( path !== 'login') {
        resolve(false);
        this.navController.navigateRoot('login');
      } else {
        resolve(true);
      }
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return new Promise((resolve) => {
        this.checkAccess(route.url.join(''), resolve);
    });
  }
}
