import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ReplaySubject } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage.service';
@Injectable({
  providedIn: 'root'
})
export class AzureAuthService {

  readonly key = 'sso:enabled'
  msalToken: ReplaySubject<string> = new ReplaySubject<string>(1);
  lastValue: string;
  enabled = false;

  constructor(
    private msalService: MsalService,
    private localStorage: LocalStorageService
  ) {
    this.msalToken.next(null);
    this.localStorage.getResource(this.key).
      then(value => {
        this.enabled = value === 'true';
        if(this.enabled){
          this.init();
        }
      })
  }

  set enableSso(value: boolean) {
    this.enabled = value;
    this.localStorage.setResource(this.key, value ? 'true': 'false')
    if(this.enabled){
      this.init();
    }

  }

  async init() {
    this.msalToken.next(null);
    const value = await this.localStorage.getResource(this.key);
    this.enabled = value === 'true';
    this.msalService.handleRedirectObservable().subscribe(result => {
      if(!result || !result.accessToken && this.enabled){
        this.msalService.loginRedirect();
      }
      else if(this.enabled){
        this.lastValue = result.accessToken;
        this.msalToken.next(this.lastValue);  
      }
      
    });
  }

  logout(){
    if(this.lastValue){
      this.enableSso = false;
      return this.msalService.logout().toPromise();
    }
    return Promise.resolve(true);
  }

}
