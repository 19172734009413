export const envConfig = {
  local: {
    encrpt:{
      key: '10fb71a33c5d6196e1b2f551f32a2e8d235ee42d4547972803a78c71f8ab8b7d',
      iv: 'e3d17f996014f5f46d73ab5d6fd99dbe',
    },
    selfUrl: 'http://localhost:8100',
    url: 'https://75bp96zr65.execute-api.us-east-1.amazonaws.com/int/api',
    basicAuth: true
  },
  dev: {
    encrpt:{
      key: '10fb71a33c5d6196e1b2f551f32a2e8d235ee42d4547972803a78c71f8ab8b7d',
      iv: 'e3d17f996014f5f46d73ab5d6fd99dbe',
    },
    selfUrl: 'https://d3d1c7z5u9gezc.cloudfront.net',
    url: 'https://75bp96zr65.execute-api.us-east-1.amazonaws.com/int/api',
    basicAuth: true
  },
  pre: {
    encrpt: {
    key: '7ef37ffff358e655ef6e4d301c995f5548e8d362b4e358c2e9aed7642fe84bb3',
    iv: '54397615720440e343666a294098e485',
    },
    selfUrl: 'https://d1sf282go6rvyw.cloudfront.net',
    url: 'https://1xexxf0wa2.execute-api.us-east-1.amazonaws.com/pre/api',
    basicAuth: false
  },
  pro: {
    encrpt: {
    key: 'e9312b6a3dc9b09561b07d740e7901e58be824e7da479a6ddded84761e2ef52c',
    iv: '067f232e4151ce227a524272f0ad6464',
    },
    selfUrl: 'https://d11f9pnkpnqw8l.cloudfront.net',
    url: 'https://c3rcfyhg20.execute-api.us-east-1.amazonaws.com/pro/api',
    basicAuth: false
  }
};

export const msalConfig = {
  dev: {
    displayName: 'azr-eapp-pre-sirmap360do-mapfredo',
    clientId: '458792c5-3ca3-4fc0-a8f0-35790030237d',
    clientSecret: 'EtVE8Q~5ahtuPShLQu-du_gZzVhtFplYgR7LNadbA',
    tenantId: '5cc6c66d-ffb2-469f-9385-cda840e57836',
    authority: 'https://login.microsoftonline.com/5cc6c66d-ffb2-469f-9385-cda840e57836/',
    redirectUri: envConfig.pre.selfUrl,
    scopes: ['458792c5-3ca3-4fc0-a8f0-35790030237d/.default'],
    postLogoutRedirectUri: envConfig.pre.selfUrl
  },
  pre_old: {
    displayName: 'EAPP-AWS-SIRMAP360ES-PRE',
    clientId: '468d22a1-162f-478f-b37a-e718834161f5',
    clientSecret: 'JU28Q~KANKRRpW81MSrm34zFYqezq-AirVYjdcPa',
    tenantId: '5cc6c66d-ffb2-469f-9385-cda840e57836',
    authority: 'https://login.microsoftonline.com/5cc6c66d-ffb2-469f-9385-cda840e57836/',
    redirectUri: envConfig.pre.selfUrl,
    scopes: ['468d22a1-162f-478f-b37a-e718834161f5/.default'],
    postLogoutRedirectUri: envConfig.pre.selfUrl
  },
  pre: {
    displayName: 'azr-eapp-pre-sirmap360do-mapfredo',
    clientId: '6fbc6003-05ac-4523-9139-10669e014c6f',
    clientSecret: 'Bfp8Q~38ZrmtZ56W1pPQPmccq8xVYmfpccFgAaDq',
    tenantId: '5cc6c66d-ffb2-469f-9385-cda840e57836',
    authority: 'https://login.microsoftonline.com/5cc6c66d-ffb2-469f-9385-cda840e57836/',
    redirectUri: envConfig.pre.selfUrl,
    scopes: ['6fbc6003-05ac-4523-9139-10669e014c6f/.default'],
    postLogoutRedirectUri: envConfig.pre.selfUrl
  },
  pro: {
    displayName: 'EAPP-AWS-SIRMAP360DO-PRO',
    clientId: 'a46a3cd2-8772-4197-9836-1156a6edd621',
    clientSecret: 'Deq8Q~cAGuld20o9GYnoo.C4ljnUYeyn5hweja.V',
    tenantId: '5cc6c66d-ffb2-469f-9385-cda840e57836',
    authority: 'https://login.microsoftonline.com/5cc6c66d-ffb2-469f-9385-cda840e57836/',
    redirectUri: envConfig.pro.selfUrl,
    scopes: ['a46a3cd2-8772-4197-9836-1156a6edd621/.default'],
    postLogoutRedirectUri: envConfig.pro.selfUrl
  }

}