import { Component } from '@angular/core';

import { AlertController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslationService } from './services/translation/translation.service';
import { AzureAuthService } from './services/azure/azureauth.service';
import { ApiService } from './services/api/api.service';
import { UserService } from './services/user/user.service';
import { IdentificationService } from './services/identification/identification.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private azureService: AzureAuthService,
    private apiService: ApiService,
    private userService: UserService,
    private identificationService: IdentificationService,
    private navCtrl: NavController
  ) {
    this.initializeApp();
    this.azureService.msalToken.subscribe(token => {
      if(token){
        this.apiService.sendLoginToken(token)
          .subscribe(response => {
            this.userService.setSfHost(response.connections.host);
            this.identificationService.setApiKey(response.securityTokens.token).then(() => {
              this.onLoginSuccess(response.data);
            });
          });
      }
    })
  }

  private onLoginSuccess(data): void {
    this.apiService.setLoggedIn();
    this.userService.setClientId(data.nuuma);
    this.userService.setCountryIsoCode(data.pais);

    this.userService.setUserId(data.userId);

    this.userService.setUserData(data);
    this.navCtrl.navigateRoot('home').then();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }


}
